<template>
  <div>
    <Header :channlids="channlids" v-bind:state="stateNumber"/>
    <div class="pc-container">

      <!--    <div class="pcView-nextPage"></div>-->
      <div class="swiper-container pcView-swiper">
        <Swiper ref="pageSwiper" :options="swiperOptions">
          <!--        趣开黑-->
          <div class="swiper-slide">
            <div class="swiperBgImg bg1"></div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper1-1 theSwiper ani"
                swiper-animate-effect="zoomIn"
            >
              <img
                  alt=""
                  class="swiper1-title"
                  src="../../assets/imgs/pc/title.png"
              />
            </div>
            <div class="swiper1-btn">
              <div class="swiper1-2 theSwiper">
                <div class="androidBox" @mouseleave="hoverAndroid(false)">
                  <div
                      class="androidCodeBg"
                      v-bind:style="' visibility: ' + androidVisibility + ';'"
                  >
                    <img
                        v-if="androidState===false"
                        alt=""
                        class="androidQrCode"
                        src="../../assets/imgs/pc/andriodCode.png"
                    />
                    <div
                        v-else
                        ref="androidQrCode"
                        class="androidQrCode"
                    ></div>
                  </div>
                  <img
                      :src="androidSrc"
                      :swiper-animate-duration="animateDuration"
                      alt=""
                      class="swiper1-android ani"
                      swiper-animate-effect="fadeInUp"
                      @mouseover="hoverAndroid(true)"
                  />
                </div>
                <div class="iosBox" @mouseleave="hoverIos(false)">
                  <div
                      class="iosCodeBg"
                      v-bind:style="' visibility: ' + iosVisibility + ';'"
                  >
                    <img
                        v-if="iosState===false"
                        alt=""
                        class="iosQrCode"
                        src="../../assets/imgs/pc/iosCode.png"
                    />
                    <div v-else ref="iosCode" class="iosQrCode">

                    </div>
                  </div>
                  <img
                      :src="iosSrc"
                      :swiper-animate-duration="animateDuration"
                      alt=""
                      class="swiper1-ios ani"
                      swiper-animate-effect="fadeInUp"
                      @mouseover="hoverIos(true)"
                  />
                </div>
                <div class="pcBox" @mouseleave="hoverPc(false)">
                  <img
                      :src="pcSrc"
                      :swiper-animate-duration="animateDuration"
                      alt=""
                      class="swiper1-android ani"
                      swiper-animate-effect="fadeInUp"
                      @click="Pcdownload"
                      @mouseover="hoverPc(true)"
                  />
                </div>
              </div>
            </div>
            <!--                            <div class="swiper1-3">-->
            <!--                              <div class="weChat weChatHeader">-->
            <!--                              <div class="weChatBox ani" swiper-animate-effect="fadeInUp"-->
            <!--                                   :swiper-animate-duration="animateDuration">-->
            <!--                                  <div class="weChatBg">-->
            <!--&lt;!&ndash;                                    <p class="weChatQrCode"></p>&ndash;&gt;-->
            <!--                                    <img class="weChatQrCode" src= "../../assets/imgs/pc/weChatShare.jpg" alt="">-->
            <!--                                    <p class="weChatText1 weChatText">扫码关注趣开黑</p>-->
            <!--                                    <p class="weChatText2 weChatText">微信公众号</p>-->
            <!--                                  </div>-->
            <!--                              </div>-->
            <!--                              </div>-->
            <!--                            </div>-->
            <!--          <div class="swiper1-4">-->
            <!--            <p class="qd">敬请期待</p>-->
            <!--          </div>-->
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper1-3 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  id="swiper-button-next"
                  slot="button-next"
                  alt=""
                  class="swiper2-mobile "
                  src="../../assets/imgs/pc/upIcon.png"
                  @click="nextHandle"
              />
            </div>
          </div>
          <div class="swiper-slide">
            <!--          首页推荐-->

            <div class="swiperBgImg bg1"></div>

            <div
                :swiper-animate-duration="animateDuration"
                class="swiper2-1 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >

              <img
                  alt=""
                  class="swiper2-mobile"
                  src="../../assets/imgs/pc/gameRooms.png"
              />
            </div>
            <!--                  <img class="swiperBgImg" src="../../assets/imgs/homepage.png" alt="">-->
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper2-2 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  alt=""
                  class="swiper2-text"
                  src="../../assets/imgs/pc/gameroomText.png"
              />
            </div>

            <div
                :swiper-animate-duration="animateDuration"
                class="swiper2-3 theSwiper ani"
                swiper-animate-delay="0.5s"
                swiper-animate-effect="pulse"
            >
              <img
                  alt=""
                  class="swiper2-book"
                  src="../../assets/imgs/pc/swipershaperoom.png"
              />
            </div>
            <!-- <div class="swiper2-4 theSwiper">
          <img
              class="swiper2-line"
              src="../../assets/imgs/pc/mobileLine1.png"
              alt=""
            /> 
          </div> -->

          </div>
          <div class="swiper-slide">
            <!--          开黑车队-->
            <div class="swiperBgImg bg2"></div>
            <!--                  <img class="swiperBgImg" src="../../assets/imgs/fleet.png" alt="">-->
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper3-1 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  alt=""
                  class="swiper3-fleet"
                  src="../../assets/imgs/pc/moblie2.png"
              />
            </div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper3-2 theSwiper ani"
                swiper-animate-delay="0.5s"
                swiper-animate-effect="pulse"
            >
              <img
                  alt=""
                  class="swiper3-game"
                  src="../../assets/imgs/pc/swipershacpend.png"
              />
            </div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper3-3 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  alt=""
                  class="swiper3-text"
                  src="../../assets/imgs/pc/gameFleet.png"
              />
            </div>
          </div>
          <div class="swiper-slide">
            <!--          开黑大厅-->
            <div class="swiperBgImg bg3"></div>
            <!--                  <img class="swiperBgImg" src="../../assets/imgs/order.png" alt="">-->
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper4-1 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  alt=""
                  class="swiper4-mibile"
                  src="../../assets/imgs/pc/mobile1.png"
              />
            </div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper4-2 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  alt=""
                  class="swiper4-text"
                  src="../../assets/imgs/pc/gameMatching.png"
              />
            </div>

            <div
                :swiper-animate-duration="animateDuration"
                class="swiper4-3 theSwiper ani"
                swiper-animate-delay="0.5s"
                swiper-animate-effect="pulse"
            >
              <img
                  alt=""
                  class="swiper4-earPhone"
                  src="../../assets/imgs/pc/swipershapematch.png"
              />
            </div>
            <!-- <div class="swiper4-4 theSwiper">
            <img
              class="swiper4-line"
              src="../../assets/imgs/pc/mobileLine3.png"
              alt=""
            />
          </div> -->
          </div>
          <div class="swiper-slide">
            <!--          大神陪练-->
            <div class="swiperBgImg bg4"></div>
            <!--                  <img class="swiperBgImg" src="../../assets/imgs/playWith.png" alt="">-->
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper5-1 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  alt=""
                  class="swiper5-order"
                  src="../../assets/imgs/pc/gameGod.png"
              />
            </div>

            <div
                :swiper-animate-duration="animateDuration"
                class="swiper5-7 theSwiper ani"
                swiper-animate-delay="0.5s"
                swiper-animate-effect="pulse"
            >
              <img alt="" class="swiper5-order" src="../../assets/imgs/pc/swipershapeGod.png"/>
            </div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper5-8 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <!--右下-->
              <img
                  alt=""
                  class="swiper5-order"
                  src="../../assets/imgs/pc/playWithText.png"
              />
            </div>
          </div>

          <div class="swiper-slide">
            <div class="swiperBgImg bg4"></div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper6-1 theSwiper ani"
                swiper-animate-effect="zoomIn"
            >
              <img
                  alt=""
                  class="swiper5-order"
                  src="../../assets/imgs/pc/gameTeam.png"
              />
            </div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper6-2 theSwiper ani"
                swiper-animate-effect="fadeInUp"
            >
              <img
                  alt=""
                  class="swiper6-text"
                  src="../../assets/imgs/pc/gameTeamText.png"
                  @click="gankUpHandle(3)"
              />
            </div>
            <div
                :swiper-animate-duration="animateDuration"
                class="swiper6-3 theSwiper ani"
                swiper-animate-effect="fadeInUp"
                @mouseleave="gankUpHandle(2)"
            >
              <img
                  :src="gameUpsrc"
                  alt=""
                  class="swiper6-text"
                  @click="gankUpHandle(3)"
                  @mouseover="gankUpHandle(1)"
              />
            </div>
            <!--                    <div class="swiper6-3">-->
            <!--                      <div class="weChat weChatFooter">-->
            <!--                        <div class="weChatBox borderBlack ani" swiper-animate-effect="fadeInUp"-->
            <!--                             :swiper-animate-duration="animateDuration">-->
            <!--                          <div class="weChatBg">-->
            <!--                            <img class="weChatQrCode" src= "../../assets/imgs/pc/weChatShare.jpg" alt="">-->
            <!--                            <p class="weChatText1 weChatText">扫码关注趣开黑</p>-->
            <!--                            <p class="weChatText2 weChatText">微信公众号</p>-->
            <!--                          </div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--          <div class="swiper6-5">-->
            <!--            <p class="qd">敬请期待</p>-->
            <!--          </div>-->
            <div class="swiper6-4">
              <PcFooter/>

            </div>

          </div>

        </Swiper>
        <div slot="pagination" class="pcView-swiper-pagination"></div>
      </div>
    </div>

    <div v-show="showLoginMessage" class="showMessage_time">{{ showMessagetitle }}</div>
  </div>

</template>

<script>
import QRCode from 'qrcodejs2-fix';
import Header from "@/components/header/header";
import PcFooter from "@/components/footer/footer";
import Request from "../../request/index";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import * as swiperAni from "../../assets/animate/index.js";

let timer;
export default {
  name: "HomePage",
  components: {
    Header,
    Swiper,
    SwiperSlide,
    PcFooter,

  },
  data() {
    return {
      channlids: '',
      iosState: false,
      androidState: false,
      pClink: '',//pc下载链接带渠道包
      platformList: [],
      showLoginMessage: false,
      showMessagetitle: '',
      showMessageTime: 0,
      gameUpsrc: require("../../assets/imgs/pc/gangUp.png"),
      iosSrc: require("../../assets/imgs/pc/ios.png"),
      androidSrc: require("../../assets/imgs/pc/android.png"),
      pcSrc: require("../../assets/imgs/pc/pclogo.png"),
      iosVisibility: "hidden",
      androidVisibility: "hidden",
      stateNumber: 0,
      showLogin: false,
      animateDuration: "0.8s", //动画播放速度
      animateDisaction: "0.0s",
      swiperOptions: {
        loop: false, //循环
        autoplay: false, //自动播放
        direction: "vertical", //竖向轮播
        spaceBetween: 10,
        // mousewheel: true,
        slidesPerView: 1, //同时显示个数
        speed: 600, //滚动速度
        mousewheel: {
          releaseOnEdges: true, //PC端释放滑动
        },
        pagination: {
          el: ".pcView-swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '#swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        on: {
          init: function () {
            swiperAni.swiperAnimateCache(this); //隐藏动画元素
            swiperAni.swiperAnimate(this); //初始化完成开始动画
          },
          slideChangeTransitionStart: function () {
            swiperAni.swiperAnimate(this); //每个slide开始切换时也运行当前slide动画
            //this.slides.eq(this.activeIndex).find('.ani')NaNpxoveClass('ani'); 动画只展现一次，去除ani类名
          },
        },
      },
    };
  },
  beforeMount() {
  },
  computed: {
    swiper() {
      return this.$refs.pageSwiper.Swiper;
    },
  },
  created() {
  },
  mounted() {
    this.channelObtains();

  },
  methods: {
    channelObtains() {
      let channelid = this.getParams().channelId;
      if (!channelid) {
        channelid = 1000;
      }
      this.channlids = channelid;
      let data = {
        platform: 0,//0:全部, 1:苹果,2:安卓,3:PC
        channel: channelid
      }
      Request.channelObtain(data).then((res) => {
        this.platformList = res;
        this.platformList.forEach((item, index) => {
          switch (item.platform) {//1:苹果,2:安卓,3:PC
            case 1:
              this.iosState = true;
              this.$nextTick(() => {
                this.creatQrCode(item.url, 1)
              })
              break;
            case 2:
              this.androidState = true;
              this.$nextTick(() => {
                this.creatQrCode(item.url, 2)
              })
              break;
            case 3:
              this.pClink = item.url;
              break;
          }
        })
      })
    },
    // 转化为二维码 1:苹果,2:安卓,3:PC
    creatQrCode(url, type) {
      if (type === 1) {
        if (this.$refs.iosCode) {
          this.$refs.iosCode.innerHTML = '';
          let qrcode = new QRCode(this.$refs.iosCode, {
            text: url, // 需要转换为二维码的内容
            colorDark: '#000000',
            colorLight: '#ffffff',
            width: 130,
            height: 130,
            correctLevel: QRCode.CorrectLevel.H
          })
        }
      }

      if (type === 2) {
        if (this.$refs.androidQrCode) {
          this.$refs.androidQrCode.innerHTML = '';
          let qrcode = new QRCode(this.$refs.androidQrCode, {
            text: url, // 需要转换为二维码的内容
            colorDark: '#000000',
            colorLight: '#ffffff',
            width: 160,
            height: 160,
            correctLevel: QRCode.CorrectLevel.H
          })
        }
      }
    },
    // 消息提示弹框
    getList: function () {
    },
    login: function () {
      this.showLogin = true;
      this.$emit("state", this.showLogin);
    },
    changeLoginModal(val) {
      this.showLogin = val;
    },
    getParams() {
      let url = location.search; //获取url中"?"符后的字串
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    },
    hoverIos(state) {
      if (state) {
        this.iosVisibility = "visible";
        // this.iosSrc=require("../../assets/imgs/pc/iosShow.png")
      } else {
        this.iosVisibility = "hidden";
        // this.iosSrc=require("../../assets/imgs/pc/ios.png")
      }
    },
    hoverAndroid(state) {
      if (state) {
        this.androidVisibility = "visible";
        // this.androidSrc=require("../../assets/imgs/pc/androidShow.png")
      } else {
        this.androidVisibility = "hidden";
        // this.androidSrc=require("../../assets/imgs/pc/android.png")
      }
    },
    hoverPc(state) {
      if (state) {
        // this.pcSrc=require("../../assets/imgs/pc/pcShow.png")
      } else {
        // this.pcSrc=require("../../assets/imgs/pc/pclogo.png")
      }
    },
    gankUpHandle(state) {
      switch (state) {
        case 1:
          this.gameUpsrc = require("../../assets/imgs/pc/gangUpSHow.png")
          break;
        case 2:
          this.gameUpsrc = require("../../assets/imgs/pc/gangUp.png")
          break;
        case 3:

          // 在新页面打开方法
          const urlPara = this.getParams();
          if (urlPara.channelId) {
            window.open('https://play.kaiheifun.com/' + "?channelId=" + urlPara.channelId, '_blank');
          } else {
            window.open('https://play.kaiheifun.com/', '_blank');
          }

          break;
      }
    },
    nextHandle() {
      this.swiper.slideNext()

    },
    Pcdownload() {
      if (this.pClink) {
        window.location.href = this.pClink;
      } else {
        window.location.href = "https://static.kaiheifun.com/app/QuKaiHei_PC_Setup.exe";
      }

    },
  },
};
</script>
<style scoped>
img {
  /*网站变灰*/
  /*filter: grayscale(100%);*/
}

bottom {
  /*filter: grayscale(100%);*/
}

@import url("../../assets/animate/animate.min.css");

.showMessage_time {
  position: absolute;
  min-width: 12.5rem;
  height: 2.5rem;
  z-index: 10;
  top: 7.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 70%);
  color: #FFFFFF;
  text-align: center;
  border-radius: .625rem;
  padding: 0 1.25rem;
  font-size: 1rem;
  line-height: 2.5rem;
  display: inline-block;
}


.swiper1-1 {
  position: absolute;
  left: 50%;
  top: 21.53%;
  height: 40%;
  transform: translateX(-50%);
  /* height: 21.11%;
  width: 26.77%; */
}

.swiper1-title {
  position: absolute;
  height: 80% !important;
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1111;
}

.theSwiper {
  position: absolute;
  /*display: flex;*/
  /*display:flex;*/
}

.swiper1-btn {
  height: 12.5rem;
  position: absolute;
  top: 65%;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: flex;
}

.swiper6-btn {
  height: 12.5rem;
  width: 100%;
  position: absolute;
  top: 40%;
  text-align: center;
  margin: 0 auto;
}

.swiper1-2 {
  position: relative;
  height: 12.5rem;
  width: 100%;
  display: flex;
  padding: 0rem 15% 0 14%;
  justify-content: space-evenly;
}

.swiper1-3 {
  left: 48.56%;
  top: 85%;
  height: 5%;
  cursor: pointer;
}

.swiper1-4 {
  position: absolute;
  height: 12.5rem;
  display: table;
  margin: 0 auto;
  top: 68.51%;
  width: 100%;
  text-align: center;
}

.swiper6-5 {
  position: relative;
  height: 12.5rem;
  display: table;
  margin: 0 auto;
  top: 63.51%;
}

.qd {
  color: #aaaaab;
  font-size: 3.125rem;
  letter-spacing: .5rem;
}

.swiper1-android {
  width: 12.5rem;
  float: left;
  cursor: pointer;
  margin-top: 3.9375rem;

}

.iosQrCode {
  transform: translate(1%, 21%);
  height: 65%;
  background-size: 100%;
  display: inline-block;
}

.iosCodeBg {
  width: 12.5625rem;
  height: 14.25rem;
  background: url("../../assets/imgs/pc/qrcodeRight.png") center no-repeat;
  background-size: 100%;
  display: inline-block;
  position: absolute;
  top: -10.375rem;
  left: 0rem;
}

.iosBox {
  cursor: pointer;
  margin-right: 1.875rem;
  height: 12.5rem;
  display: inline-block;
  position: relative;
}

.swiper1-ios {
  width: 12.5rem;
  float: left;
  cursor: pointer;
  margin-top: 3.9375rem;
}

.androidBox {
  cursor: pointer;
  height: 12.5rem;
  margin-right: 1.875rem;
  display: inline-block;
  position: relative;
}

.pcBox {
  height: 12.5rem;
  display: inline-block;
  position: relative;
}

.androidCodeBg {
  width: 12.5625rem;
  height: 14.25rem;
  background: url("../../assets/imgs/pc/qrcodeRight.png") center no-repeat;
  background-size: 100%;
  display: inline-block;
  position: absolute;
  top: -10.375rem;
  left: 0rem;
}

.androidQrCode {
  transform: translate(1%, 21%);
  height: 65%;
  background-size: 100%;
  display: inline-block;
}

.weChat {
  width: 100%;
  height: 16.25rem;
  text-align: center;
  position: absolute;
  margin: 0 auto;
}

.weChatHeader {
  top: 61.75%;
}

.weChatFooter {
  top: 49.4%;
}

.weChatQrCode {
  /*background: url("../../assets/imgs/pc/weChatShare.jpg");*/
  width: 100%;
  height: 100%;
  background-size: 100%;
  display: inline-block;
  overflow: hidden;
  padding: 0rem;
}

.weChatText {
  width: 100%;
  height: 1.25rem;
  text-align: center;
  font-family: "Microsoft YaHei UI";
  font-size: 1rem;
  font-weight: 700;
  color: black;
}

.weChatBox {
  height: 15.75rem;
  display: table;
  background: #ffffff;
  border-radius: .625rem;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.borderBlack {
  border: .0625rem solid black;
}

.weChatBg {
  width: 12.5rem;
  height: 12.5rem;
}

.weChatText1 {
  margin-top: -0.3125rem;
}

.weChatText2 {
  margin-top: -0.625rem;
}

.swiper1-3 {
  /*display: block;*/
  /*width: 100%;*/
}

.swiper2-1 {
  /* left: 0%;
  top: 24%;
  height: 30%;
 width: 27.65%;*/
}

.swiper2-2 {
  left: 51%;
  top: 30.55%;
  height: 35.74%;
  /*width: 27.65%;*/
}

.swiper2-book,
.swiper2-mobile,
.swiper2-line,
.swiper2-text,
.swiper3-line,
.swiper3-game,
.swiper4-mibile,
.swiper5-order,
.swiper4-text,
.swiper4-earPhone,
.swiper3-text,
.swiper1-title,
.swiper6-text {
  height: 100%;
}

.swiper6-text {
  cursor: pointer;
}

.swiper4-text {
  /*position: absolute;*/
}

.swiper2-1 {
  left: 9.03%;
  top: 19.9%;
  height: 73.46%;
  z-index: 12;
}

.swiper2-1 > img {
  max-width: 100%;
}

.swiper2-3 {
  left: 70.84%;
  top: 20.81%;
  height: 36%;
  z-index: 13;
}

.swiper2-4 {
  left: 49.27%;
  top: 34.72%;
  height: 30.55%;
  z-index: 11;
}

.swiper3-1 {
  left: 9.03%;
  top: 19.9%;
  height: 73.46%;
  z-index: 12;
}

.swiper3-fleet {
  height: 100%;
}

.swiper3-2 {
  left: 70.84%;
  top: 20.81%;
  height: 36%;
  z-index: 13;
}

.swiper3-3 {
  left: 51%;
  top: 30.55%;
  height: 35.74%;
}

.swiper3-4 {
  left: 11.14%;
  top: 32.31%;
  height: 28.88%;
  z-index: 11;
}

.swiper4-1 {
  left: 9.03%;
  top: 19.9%;
  height: 73.46%;
  z-index: 12;
}

.swiper4-2 {
  left: 51%;
  top: 30.55%;
  height: 35.74%;
}

.swiper4-3 {
  left: 70.84%;
  top: 20.81%;
  height: 36%;
  z-index: 13;
}

.swiper4-4 {
  left: 50.57%;
  top: 34.35%;
  height: 30.55%;
  z-index: 11;
}

.swiper5-1 {
  left: 16.03%;
  top: 23.9%;
  height: 66.46%;
  z-index: 12;
}

.swiper5-2 {
  left: 15.93%;
  top: 35.55%;
  height: 13.14%;
}

.swiper5-3 {
  left: 8.02%;
  top: 51.38%;
  height: 11.11%;
}

.swiper5-4 {
  left: 31.3%;
  top: 15.55%;
  height: 11.11%;
}

.swiper5-5 {
  left: 28.02%;
  top: 47.12%;
  height: 11.11%;
}

.swiper5-6 {
  left: 22.5%;
  top: 70.64%;
  height: 11.11%;
}

.swiper5-7 {
  left: 70.84%;
  top: 24.81%;
  height: 36%;
  z-index: 13;
  z-index: 13;
}

.swiper5-8 {
  left: 54.73%;
  top: 34.14%;
  height: 33.2%;
}

.swiper6-1 {
  left: 12.03%;
  top: 19.9%;
  height: 57.46%;
  z-index: 12;
}

.swiper6-2 {
  left: 53%;
  top: 29%;
  height: 25%;
  /*width: 27.65%;*/
}

.swiper6-3 {
  left: 61%;
  top: 60%;
  height: 7%;
}

.swiper6-4 {
  width: 100%;
  position: absolute;
  bottom: .625rem;
  text-align: center;
  white-space: nowrap;
  z-index: 1111;
}

.bg1 {
  width: 100%;
  height: 100%;
  background: url("../../assets/imgs/pc/sidebg.png");
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  /*background-attachment: fixed; !*关键*!*/
  background-position: center;
}

.bg2 {
  background: url("../../assets/imgs/pc/sidebg.png");
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-attachment: fixed;  !*关键*!*/
  background-position: center;
}

.bg3 {
  background: url("../../assets/imgs/pc/sidebg.png");
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-attachment: fixed;  !*关键*!*/
  background-position: center;
}

.bg4 {
  background: url("../../assets/imgs/pc/sidebg.png");
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-attachment: fixed;  !*关键*!*/
  background-position: center;
}
</style>
<style lang="less" scoped>
/*swpier样式*/
.swiper-slide {
  min-width: 62.5rem;
  max-width: 118.75rem;
  margin: 0 auto;
  background: #131722;
  position: relative;
}

.swiperBgImg {
  //filter: grayscale(100%);
  // background: #131722;
  width: 100%;
  height: 100%;
  position: absolute;
  //display: inline-block;
}

.swiper {
  background: #131722;
}

.swiper-container {
  background: #131722;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  height: 100vh;
}

.pcView-swiper-pagination {
  right: 3% !important;
  position: fixed;
  z-index: 200;
  margin: 0 auto;
  width: .9375rem;
  height: 6.25rem;
  top: 50%;
  transform: translate3d(0rem, -50%, 0);
}

.pcView-swiper-pagination /deep/ .swiper-pagination-bullet {
  width: .625rem;
  height: .625rem;
  opacity: 0.5;
  background-color: gray;
}

.pcView-swiper-pagination /deep/ .swiper-pagination-bullet-active {
  background-color: #659fff;
  opacity: 0.9;
  width: .625rem;
  height: 1.5625rem;
  border-radius: .625rem;
}

.pc-container {
  min-width: 75rem;
  // max-width:225rem;
  width: 100%;
  margin: 0 auto;

  .swiper-container {
    .swiper-pagination {
      position: fixed;
      width: 62.5rem;
    }
  }
}

.container {
  width: 75rem;
  margin: 0 auto;
}

.pcView-nextPage {
  position: fixed;
  z-index: 10;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  width: 3.125rem;
  height: 3.125rem;
  background: url("../../assets/imgs/pc/next.png") center no-repeat;
  background-size: contain;
}

/* 媒介查询 */
// @media only screen and (min-width: 60rem) and (max-width:75rem){
//   .swiper7-1 {
//     left: -20%;
//     top: 17.9%;
//     z-index: 12;
//     width: 25rem;
//     height: 33.9375rem;
// }
</style>
