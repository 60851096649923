<template>

  <div class="pc-header">
    <div class="pc_headerflex">
      <a class="pc_title">
        <img alt="" class="pc-logo" src="../../assets/imgs/pc/logo.png"/>
        <svg-icon class-name="pc-logo-text" icon-class="logoText"></svg-icon>
      </a>
      <div class="pclinkbox">
        <div v-for="(item,index) in headlist" :key="index" class="pc_page">
          <span class="pageone pagerighy" @click="pageonload(item.id,index)">
            <span :class="state===index?'pcactive':''">{{ item.name }}
            </span>
            <div v-show="state===index" class="line">
            </div>
          </span>
        </div>
      </div>
    </div>
    <img :src="itmsrc" alt="" class="gankUp" @click="gankHandle(3)" @mouseleave="gankHandle(2)"
         @mouseover="gankHandle(1)">
  </div>


</template>

<script>
export default {
  name: "headMenu",
  props: {
    state: Number,//高亮被选中的菜单
    channlids: ""
  },
  data() {
    return {
      itmsrc: require("../../assets/imgs/pc/gangUp.png"),//立刻开黑图片路径
      Headtype: 0,
      linestate: true,
      linestatets: false,
      navigationList: [
        "带带APP",
        "游戏陪玩",
        "语音聊天室",
        "伴奏上传",
        "关于我们",
      ],
      headlist: [
        {
          id: 0,
          name: '首页',
        },
        {
          id: 1,
          name: '充值'
        },
        {
          id: 2,
          name: '资讯'
        },
        {
          id: 3,
          name: '上传伴奏'
        }
      ],
      stateNumber: this.state,
    };
  },
  methods: {
    gankHandle(state) {
      switch (state) {
        case 1:
          this.itmsrc = require("../../assets/imgs/pc/gangUpSHow.png")
          break;
        case 2:
          this.itmsrc = require("../../assets/imgs/pc/gangUp.png")
          break;
        case 3:

          // 在新页面打开方法
          const urlPara = this.getParams();
          if (urlPara.channelId) {
            window.open('https://play.kaiheifun.com/' + "?channelId=" + urlPara.channelId, '_blank');
          } else {
            window.open('https://play.kaiheifun.com/', '_blank');
          }
          break;
      }
    },
    navigationClick: function (index) {
      this.stateNumber = index;
    },
    pageonload(type, ind) {
      this.Headtype = type;
      switch (type) {
          // 跳转到首页
        case 0:
          const urlPara = this.getParams();
          if (urlPara.channelId) {
            this.$router.replace('/' + "?channelId=" + urlPara.channelId);
          } else {
            this.$router.replace('/');
          }
          break;
        case 1:
          // 跳转到充值页面
          if (this.channlids) {
            this.$router.push('/pay' + "?channelId=" + this.channlids);
          } else {
            this.$router.push('/pay');
          }
          break;
        case 2:
          if (this.$route.path === "/news") {
            this.$parent.getNewsList();
          }else{
            if (this.channlids) {
              this.$router.push('/news' + "?channelId=" + this.channlids);
            } else {
              this.$router.push('/news');
            }
          }
          break;
        case 3:
          let url = "";
          if (process.env.VUE_APP_APP_NAME === "development" || process.env.VUE_APP_APP_NAME === "test") {
            url = "https://pc.wuhanzhuoqu.tech/upload_music";
          } else {
            url = "https://play.kaiheifun.com/upload_music";
          }
          window.open(url);
          break;
      }
    },
    pageload() {
      this.linestate = false;
      this.linestatets = true;
      const urlPara = this.getParams();
      // if(urlPara.channelId){
      //   window.location.href = "https://play.kaiheifun.com"+"?channelId="+urlPara.channelId;
      // }else{
      //   window.location.href = "https://play.kaiheifun.com/";
      // }
    },
    getParams() {
      let url = location.search; //获取url中"?"符后的字串
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    }
  },
};
</script>

<style scoped>
body {
  /*网站变灰*/
  /*filter: grayscale(100%);*/
}

.pc-header > .pc_headerflex > .pclinkbox > .pc_page {
  line-height: 56px;
  float: left;
}

.pclinkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pc-header > .gankUp {
  height: 40px;
  z-index: 1111;
  cursor: pointer;
}

.pc-header > .pc_headerflex {
  width: 50%;
}

.pc-header > .pc_headerflex > .pclinkbox > .pc_page > .pagerighy {
  margin-right: 50px;
}

.pcactive {
  font-size: 23px;
  font-weight: 500;
  color: #FFFFFF;


}

.pc-header > .pc_headerflex > .pclinkbox > .pc_page > .pageone > .line {
  width: 30px;
  height: 6px;
  background: linear-gradient(270deg, #3CBAFF 0%, #3892EA 100%);
  border-radius: 3px;
  margin: 10px auto;
}

.pc-header > .pc_headerflex > .pclinkbox > .pc_page > .pageone {
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  color: #cbcbcb;
  line-height: 18px;
  cursor: pointer;
}

.pc-header {
  /*filter: grayscale(100%);*/
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  /* transform: translateX(-50%); */
  padding: 30px 20px 30px 0px;
  width: 100%;
  height: 90px;
  justify-content: space-around;
  align-items: center;
   background-color: transparent;
}

.pc-header > .pc_headerflex > a {
  cursor: auto;
  float: left;
  border: 0;
  outline: none;
  padding-right: 5%;
  height: 60px;
}

.pc-logo {
  height: 60px;
  display: inline-block;
  float: left;
}
.pc-logo-text{
  width: 100px;
  height: 60px;
  display: inline-block;
  float: left;
  margin-left: 12px;
}
</style>
